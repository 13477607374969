<template>
  <div style="padding:5px 0px 0px 0px; background-color: white; border-radius: 10px;" class="responsiveTable-container">
    <div v-if="isAdmin || islogistics">
      <tabs
        :tabs="tabsList"
        @updateIndex="updateIndex"
        :currentIndex="upIndex"
      ></tabs>
      <jput v-if="upIndex == 0" :jputlist="jputlist" @list="list"></jput>
      <customer v-if="upIndex == 1" @list="list"></customer>
      <surveyor v-else-if="upIndex == 2" @list="list"></surveyor>
    </div>
    <jput v-else></jput>
    <!-- customerAdmin list -->
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import jput from "@/components/company/company-jput.vue";
import customer from "@/components/company/company-customer.vue";
import surveyor from "@/components/company/company-surveyor.vue";
import { getcompanyList, getcompanyLists } from "@/services/user";
import { mapState } from "vuex";

export default {
  name: "company",
  components: { tabs, customer, jput, surveyor },

  data() {
    return {
      tabsList: [
        { label: "JPUT", count: 10, index: "0" },
        { label: "Customer", count: 10, index: "1" },
        { label: "Surveyor", count: 10, index: "2" },
      ],
      upIndex: 0,
      tabsvalidate: true,
      jputlist: [],
    };
  },
  created() {
    this.getuser();
  },
  computed: {
    ...mapState(["userInfo"]),
    islogistics() {
      return this.userInfo.userType == 1 && this.userInfo.role == 1;
    },
    isCustomerAdmin() {
      return this.userInfo.userType == 2 && this.userInfo.role == 4;
    },
    isSurveyorAdmin() {
      return this.userInfo.userType == 3 && this.userInfo.role == 6;
    },
    isAdmin() {
      return this.userInfo.userType == 0;
    },
  },
  methods: {
    //控制tabs 切换显示
    getuser() {
      getcompanyLists().then((res) => {
        if (res.code == 200) {
          this.tabsList.forEach((item) => {
            if (item.label == "JPUT") {
              item.count = res.data.jput;
            } else if (item.label == "Customer") {
              item.count = res.data.Customer;
            } else {
              item.count = res.data.Surveyor;
            }
          });
        }
      });
    },
    updateIndex(index) {
      // debugger
      this.upIndex = index;
    },
    list() {
      this.getuser();
    },
  },
};
</script>

<style scoped lang="scss">
.responsiveTable-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 90px);
}
</style>

<template>
  <!-- Customer -->
  <div class="box-card p-8">
    <el-row type="flex" justify="end" class="Topinp">
      <el-col :span="9">
        <div class="Topinput">
          <el-input
            size="small"
            :placeholder="$t('btn.SearchCompany')"
            v-model="Tankinput"
            @keyup.enter.native="Tankinputval(Tankinput)"
          >
            <span @click="Tankinputval(Tankinput)">
              <svg-icon slot="suffix" iconClass="search" />
            </span>
          </el-input>
          <el-button size="small" class="Combtn" plain @click="TankBtn">{{
            $t("btn.CreateCompany")
          }}</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24"><div class="placeholder"></div></el-col>
    </el-row>
    <el-row v-loading="custloading">
      <el-col :span="9" class="content">
        <div
          class="Cstlist"
          v-for="(item, index) in userData"
          :key="index"
          :class="{ active: active == index }"
          @click="CstList(index, item)"
        >
          <div>
            <el-avatar :size="29" :src="circleUrl"></el-avatar>
          </div>
          <div class="text">
            <p>{{ item.nickName }}</p>
            <div>
              <p>UEN {{ item.uenNumber }}</p>
            </div>
            <P>{{ item.email }}</P>
            <el-tag
              style="margin-top: 4px;"
              class="Statustag"
              :type="
                item.status == '0'
                  ? 'success'
                  : item.status == '1'
                  ? 'danger'
                  : 'pink'
              "
              disable-transitions
              :color="
                item.status == '0'
                  ? '#CEF9CD'
                  : item.status == '1'
                  ? '#FFE6E1'
                  : '#FFEFD9'
              "
            >
              {{
                item.status == "0"
                  ? $t("state.Active")
                  : item.status == "1"
                  ? $t("state.Deactivated")
                  : $t("state.PendingVerification")
              }}
            </el-tag>
          </div>
          <div class="Cstbtn">
            <el-dropdown
              v-if="item.status == 0 || item.status == 1"
              trigger="click"
              :ref="'popoverRef_' + item.id"
            >
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="
                    handleEditUserClick(item)
                  "
                  >Edit User</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="handlefreezeClick(item)"
                  >{{
                    item.status == 0
                      ? "Deactivate User"
                      : "Activate User"
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown
              v-else
              trigger="click"
              :ref="'popoverRef_' + item.id"
            >
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="
                    handleResendRequestClick(item)
                  "
                  >Resend Request</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="handleDeleteUserClick(item)"
                  >Delete User</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
      <el-col :span="15" class="Comtable px-2">
        <div>
          <el-table
            height="70vh"
            :data="tableData"
            :cell-style="getCellStyle"
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-top': '2px solid #D6D9E1 ',
              'border-bottom': '1px solid #D6D9E1',
            }"
            >
            <el-table-column align="left" type="index" :label="$t('table.no')">
            </el-table-column>
            <el-table-column
              align="left"
              prop="nickName"
              :label="$t('table.Name')"
            >
            </el-table-column>
            <el-table-column
              align="left"
              prop="email"
              :label="$t('table.EmailAddress')"
            >
            </el-table-column>
            <el-table-column align="left" prop="role" :label="$t('table.Role')">
              <template slot-scope="scope">
                {{ rolesMap[scope.row.role] }}
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              prop="address"
              :label="$t('table.Status')"
            >
              <template slot-scope="scope">
                <!-- <el-tag :class="scope.row.tag== 'Active'? 'success':(scope.row.tag== 'Deactivated'?'danger': 'pink' )" disable-transitions>
              </el-tag> -->
                <el-tag
                  class="Statustag"
                  :type="
                    scope.row.status == '0'
                      ? 'success'
                      : scope.row.status == '1'
                      ? 'danger'
                      : 'pink'
                  "
                  disable-transitions
                  :color="
                    scope.row.status == '0'
                      ? '#CEF9CD'
                      : scope.row.status == '1'
                      ? '#FFE6E1'
                      : '#FFEFD9'
                  "
                >
                  {{
                    scope.row.status == "0"
                      ? $t("state.Active")
                      : scope.row.status == "1"
                      ? $t("state.Deactivated")
                      : $t("state.PendingVerification")
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="right" fixed="right">
              <template slot-scope="scope">
                <el-dropdown
                  v-if="scope.row.status == 0 || scope.row.status == 1"
                  trigger="click"
                  :ref="'popoverRef_' + scope.row.id"
                >
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="
                        handleEditUserClick(scope.row, scope.$index)
                      "
                      >Edit User</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="handlefreezeClick(scope.row, scope.$index)"
                      >{{
                        scope.row.status == 0
                          ? "Deactivate User"
                          : "Activate User"
                      }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  v-else
                  trigger="click"
                  :ref="'popoverRef_' + scope.row.id"
                >
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="
                        handleResendRequestClick(scope.row)
                      "
                      >Resend Request</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="
                        handleDeleteUserClick(scope.row)
                      "
                      >Delete User</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 新增弹窗 -->
    <el-dialog
      width="360px"
      :title="$t('jm.CreateCompany')"
      :visible.sync="dialogFormVisible"
      @close="handleCreateCompanyDialogClose"
    >
      <el-form
        ref="form"
        size="small"
        label-position="top"
        :model="form"
        :rules="rules"
      >
        <el-form-item :label="$t('jm.CompanyName')" prop="nickName">
          <el-input
            size="small"
            v-model="form.nickName"
            clearable
            autocomplete="off"
            maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jm.UENNumber')" prop="uenNumber">
          <el-input
            size="small"
            clearable
            v-model="form.uenNumber"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email" :label="$t('jm.EmailAddress')">
          <el-input
            size="small"
            v-model="form.email"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSaveClick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 编辑用户弹窗 -->
    <el-dialog
      width="360px"
      title="Edit Company"
      :visible.sync="editUserDialog"
    >
      <el-form
        ref="editUserForm"
        size="small"
        label-position="top"
        :model="editUserForm"
        :rules="rules"
      >
        <el-form-item :label="$t('jm.CompanyName')" prop="nickName">
          <el-input
            size="small"
            v-model="editUserForm.nickName"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('jm.UENNumber')" prop="uenNumber">
          <el-input
            size="small"
            oninput="value=value.replace(/[^\d]/g,'')"
            clearable
            v-model="editUserForm.uenNumber"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email" :label="$t('jm.EmailAddress')">
          <el-input
            size="small"
            v-model="editUserForm.email"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Editformclick"
          >{{ $t("btn.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCellStyle } from "@/utils/getCellStyle";
import {
  getCSSList,
  addUser,
  editUser,
  freezeUser,
  unFreezeUser,
  deleteUser,
  revalidateEmail,
} from "@/services/user";

const rolesMap = {
  0: "Admin",
  1: "Terminal Logistics",
  2: "Terminal Ops",
  3: "Terminal Team Leader",
  4: "Company Admin",
  5: "Customer User",
  6: "Surveyor Admin",
  7: "Surveyor User",
  8: "Barge",
};

export default {
  data() {
    return {
      tableData: [],
      userData: [],
      Tankinput: "",
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogFormVisible: false,
      editUserDialog: false,
      form: {
        nickName: "",
        uenNumber: "",
        email: "",
        userType: 2,
        role: 4,
      },
      editUserForm: {
        nickName: "",
        uenNumber: "",
        email: "",
        userId: "",
      },
      rules: {
        nickName: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        uenNumber: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
        email: [{ required: true, message: "Please Input", trigger: "blur" }],
      },
      active: "",
      custloading: true,
      rolesMap,
    };
  },
  created() {
    this.getList();
    // this.CstList();
  },
  watch: {
    dialogFormVisible(val) {
      this.$nextTick(() => {
        this.form.nickName = '';
        this.form.uenNumber = '';
        this.form.email = '';
        this.form.role = '';
      });
    },
    // editUserDialog(){
    //   this.$nextTick(() => {
    //     this.editUserForm.nickName = '';
    //     this.editUserForm.email = '';
    //     this.editUserForm.role = '';
    //   });
    // }
  },
  methods: {
    reset(){
          this.editUserForm.nickName = '';
          this.editUserForm.email = '';
          this.editUserForm.role = '';
    },
    // input框
    Tankinputval(value) {
      this.getList();
    },
    // 创建
    TankBtn() {
      this.dialogFormVisible = true;
      this.reset()
    },
    getList() {
      getCSSList(2, this.Tankinput).then((res) => {
        if (res.code == 200) {
          this.userData = res.data;
          if (this.userData.length > 0) {
            this.tableData = this.userData[0].sysUsers;
          }
        }
      }).finally(() => {
        this.custloading = false;
      });
    },
    handleSaveClick() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.form.role=4
          addUser(this.form).then((res) => {
            if (res.code == 200) {
              this.dialogFormVisible = false;
              this.$message.success(this.$t("status.success"));
              this.getList();
              this.$emit("list");
            }
          });
        }
      });
    },
    CstList(val, item) {
      this.tableData = item.sysUsers;
      val ? (this.active = val) : (this.active = 0);
    },
    getCellStyle,

    // 表格修改
    handleEditUserClick(val, index) {
      this.editUserDialog = true;
      this.editUserForm = {
        ...val
      }
      // this.editUserForm.uenNumber = val.uenNumber;
      // this.editUserForm.nickName = val.nickName;
      // this.editUserForm.email = val.email;
      // this.editUserForm.userId = val.userId;
    },
    // 表格修改提交
    Editformclick() {
      this.$refs.editUserForm.validate((res) => {
        if (res) {
          editUser(this.editUserForm).then((res) => {
            if (res.code == 200) {
              this.editUserDialog = false;
              this.$message.success(this.$t("status.success"));
              this.getList();
              this.active = 0;
            }
          });
        }
      });
    },
    // 冻结/解冻 用户
    handlefreezeClick(val, index) {
      let userId = val.userId;
      if (val.status == 0) {
        freezeUser(userId).then((res) => {
          if (res.code == 200) {
            this.$message.success(this.$t("status.success"));
            this.getList();
          }
        });
      } else {
        unFreezeUser(userId).then((res) => {
          if (res.code == 200) {
            this.$message.success(this.$t("status.success"));
            this.getList();
          }
        });
      }
    },
    handleCreateCompanyDialogClose () {
      this.$refs.form.clearValidate()
    },
    handleResendRequestClick(row) {
      revalidateEmail(row.userId).then(() => {
        this.$message.success(this.$t("status.success"));
      });
    },
    handleDeleteUserClick(row) {
      deleteUser(row.userId).then(() => {
        this.$message.success(this.$t("status.success"));
        this.getList();
      })
    },
  },
};
</script>

<style scoped lang="scss">
.box-card {
  // margin-top: 20px;
}
.Topinp {
  position: relative;
  .Topinput {
    display: flex;
    position: absolute;
    top: -65px;
    right: 0px;

    .Combtn {
      margin-left: 20px;
    }
  }
}

.el-table {
  font-size: 12px;
}
.Statustag {
  border-radius: 10px;
}
.el-button--default {
  color: #fff;
  background-color: #008CDB;
}
.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}
.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-icon-more {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  color: #82889c;
}
.content {
  height: calc(88vh - 80px - 32 * 2px - 56px);
  border-right: 1px solid #d6d9e1;
  border-top: 2px solid #d6d9e1;
  background-color: #fafafa;
  overflow-y: auto;
  .Cstlist {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding: 14px;
    box-sizing: border-box;
    height: 100px;
    font-size: 11px;
    // border-top: 1px solid #d6d9e1;
    border-bottom: 1px solid #d6d9e1;
    .text {
      margin-left: 17px;
      width: 50%;
    }
    .Cstbtn {
      display: flex;
      margin-left: 86px;
      .svgs {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        border: 1.5px solid #82889c;
        width: 35px;
        height: 35px;
        margin-right: 12px;
      }
    }
  }
  .active {
    background: #ffffff;
  }
}
.placeholder {
  height: 66px;
  background-color: #fff;
}
.Comtable {
  height: calc(88vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
}
</style>
